import { useState } from "react";
import Button from "Atoms/Button";
import "./style.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown, { Icon } from "Atoms/Button/Dropdown";
import { useTranslation } from "react-i18next";
import ModalLanguage from "Atoms/Button/Modal";
import i18n from "i18n";

export default function Header({
  bgHeader,
  home,
  restaurant,
  supplier,
  headingText,
  headerButtonLeft,
  headerButtonRight,
  logoYellow,
  noButtons,
  lastModificationDate,
  noButtonsWithoutModificationText,
}) {
  const { t } = useTranslation("translation");
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const lang = location.pathname.split("/")[1];

  const navLinkStyles = ({ isActive }) => {
    return {
      textDecoration: isActive ? "underline" : "",
      textUnderlineOffset: isActive ? "8px" : "",
      textDecorationThickness: isActive ? "3px" : "",
    };
  };

  return (
    <nav className={`relative ${!!bgHeader ? bgHeader : "bg-bluePrimary"}`}>
      <div className="flex justify-between items-start lg:pt-10 pt-4 lg:px-10 px-4">
        <div className="flex justify-start lg:flex-1">
          <Link to={`/${lang}`}>
            <img
              src={
                logoYellow
                  ? "/SVGs/horeca-orders-logo-yellow.svg"
                  : "/SVGs/horeca-orders-logo.svg"
              }
              alt="Logo of Horeca Orders"
            />
          </Link>
        </div>
        <section className="flex lg:hidden">
          <ModalLanguage
            show={openLanguageModal}
            onClose={() => setOpenLanguageModal(false)}
            onCloseMenu={() => setIsNavOpen(false)}
          ></ModalLanguage>
          <div
            className="space-y-2 mt-2 mr-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span
              className={`block h-0.5 w-8 ${restaurant && "bg-bluePrimary"} 
              ${home && "bg-white"}  ${supplier && "bg-black"}`}
            ></span>
            <span
              className={`block h-0.5 w-8 ${restaurant && "bg-bluePrimary"} 
              ${home && "bg-white"} ${supplier && "bg-black"}`}
            ></span>
            <span
              className={`block h-0.5 w-8 ${restaurant && "bg-bluePrimary"} 
              ${home && "bg-white"} ${supplier && "bg-black"}`}
            ></span>
          </div>

          <div
            className={`${isNavOpen && "overflowHidden"} ${isNavOpen ? "showMenuNav pt-4 flex" : "hideMenuNav"
              }`}
          >
            <div
              className="absolute top-0 right-0 px-6 py-6"
              onClick={() => setIsNavOpen(false)}
            >
              <img
                className="w-7 h-7"
                src="/SVGs/closeBtn.svg"
                alt="Close Header Dropdown"
              />
            </div>
            <Link to={`/${lang}`}>
              <img
                src="/SVGs/horeca-orders-logo-yellow.svg"
                alt="Logo of Horeca Orders"
              />
            </Link>

            <div className="w-full bg-greyHairline h-px my-4" />
            <ul className="flex flex-col items-center justify-start ">
              <li
                className={`my-4 ${restaurant && "lg:text-bluePrimary text-white"
                  } ${(home || supplier) && "text-white"
                  } uppercase font-openSans`}
              >
                <Link to={`/${lang}/restaurante`}>{t("restaurants")}</Link>
              </li>
              <li
                className={`my-4 ${restaurant && "lg:text-bluePrimary text-white"
                  } ${(home || supplier) && "text-white"
                  } uppercase font-openSans`}
              >
                <Link to={`/${lang}/furnizori`}>{t("suppliers")}</Link>
              </li>
              <li
                className={`my-4 hidden ${restaurant && "lg:text-bluePrimary text-white"
                  } ${(home || supplier) && "text-white"
                  } uppercase font-openSans`}
              >
                <Link to={`/${lang}/povesti`}>{t("stories")}</Link>
              </li>
              <li
                className="my-4
                   lg:text-bluePrimary text-white
                uppercase font-openSans"
              >
                <Button
                  link="https://app.horecaorders.com/login"
                  text={t("login")}
                  styles={"text-black py-1"}
                  fontSizeText={"text-base"}
                />
              </li>
            </ul>
            <div className="w-full bg-greyHairline h-px my-4" />
            <ul className="flex flex-col items-center justify-start ">
              <li
                className={`my-4 ${restaurant && "lg:text-bluePrimary text-white"
                  } ${(home || supplier) && "text-white"
                  } uppercase font-openSans`}
              >
                <Link to={`/${lang}/despre`}>{t("about")}</Link>
              </li>
              <li
                className={`my-4 ${restaurant && "lg:text-bluePrimary text-white"
                  } ${(home || supplier) && "text-white"
                  } uppercase font-openSans`}
              >
                <Link to={`/${lang}/intrebari-frecvente`}>{t("faq")}</Link>
              </li>
            </ul>
            <div className="w-full bg-greyHairline h-px my-4" />
            <div
              onClick={() => setOpenLanguageModal(true)}
              className="flex justify-start items-center text-white"
            >
              <div className="w-4 h-4 mr-2 ">
                <Icon />
              </div>
              <span className={`uppercase font-openSans`}>{i18n.language}</span>
            </div>
          </div>
        </section>
        <div className="hidden lg:flex md:space-x-10 items-center">
          <NavLink
            style={navLinkStyles}
            to={`/${lang}/restaurante`}
            className={`text-base font-semibold
            ${restaurant && "text-bluePrimary"}
            ${home && "text-white"}
            ${supplier && "text-black"
              } font-openSans uppercase hover:underline hover:underline-offset-8 decoration-3 transition duration-300 delay-150`}
          >
            {t("restaurants")}
          </NavLink>
          <NavLink
            style={navLinkStyles}
            to={`/${lang}/furnizori`}
            className={`text-base font-semibold 
            ${restaurant && "text-bluePrimary"} 
            ${home && "text-white"} 
            ${supplier && "text-black"
              } font-openSans uppercase hover:underline hover:underline-offset-8 decoration-3 transition duration-300 delay-150`}
          >
            {t("suppliers")}
          </NavLink>
          <NavLink
            style={navLinkStyles}
            to={`/${lang}/povesti`}
            className={`hidden text-base font-semibold 
            ${restaurant && "text-bluePrimary"} 
            ${home && "text-white"} 
            ${supplier && "text-black"
              } font-openSans uppercase hover:underline hover:underline-offset-8 decoration-3 transition duration-300 delay-150`}
          >
            {t("stories")}
          </NavLink>
          <Button
            link="https://app.horecaorders.com/login"
            text={t("log_in")}
            styles={"text-black text-lg py-1"}
          />
          <Dropdown restaurant={restaurant} home={home} supplier={supplier} />
        </div>
      </div>
      <h1
        dangerouslySetInnerHTML={{
          __html: headingText,
        }}
        className={`flex ${noButtons
            ? `${noButtons}`
            : "justify-center lg:px-8 px-16 text-center"
          }   
        ${restaurant && "text-bluePrimary text-4.5xl"} 
        ${home && `text-white ${home}`} 
        ${supplier && "text-black text-4.5xl"} 
        ${noButtonsWithoutModificationText && "lg:pb-32 pb-14"
          } font-extrabold font-openSans italic  sm:text-5xl lg:text-7xl lg:mt-12 mt-8 lg:whitespace-pre-line leading-10 text-ellipsis overflow-hidden`}
      ></h1>
      {(!noButtons || noButtonsWithoutModificationText) && (
        <div className="flex flex-col lg:flex-row lg:flex justify-center items-center lg:space-x-4 lg:space-y-0 space-y-4 mt-8 lg:pb-32 pb-10">
          {!headerButtonLeft?.externalLink ? (
            <Link to={headerButtonLeft?.link}>
              <Button
                text={headerButtonLeft?.text}
                styles="lg:w-48 w-64 justify-center px-2 py-3"
              />
            </Link>
          ) : (
            <Button
              text={headerButtonLeft?.text}
              styles="lg:w-48 w-64 justify-center px-2 py-3"
              link={headerButtonLeft?.externalLink}
            />
          )}
          {!headerButtonRight?.externalLink ? (
            <Link to={headerButtonRight?.link}>
              <Button
                text={headerButtonRight?.text}
                styles="lg:w-48 w-64 justify-center px-2 py-3"
              />
            </Link>
          ) : (
            <Button
              text={headerButtonRight?.text}
              styles="lg:w-48 w-64 justify-center px-2 py-3"
              link={headerButtonRight?.externalLink}
            />
          )}
        </div>
      )}
      {noButtons && (
        <div
          className={`${restaurant ? "text-black" : "text-white"
            } font-bold font-openSans text-3xl lg:ml-52 2xl:ml-96 3xl:ml-100 lg:pb-32 pb-10 mt-10 lg:mx-0 mx-8`}
        >
          {lastModificationDate}
        </div>
      )}
    </nav>
  );
}
